<template>
	<!-- 个人中心 创作稿件 组件 -->
	<div>
		<a-card :bordered="false" class="header-solid h-full mb-24" style="overflow: hidden" :bodyStyle="{ padding: 0 }">
			<template #title>
				<a-row type="flex" align="middle">
					<a-col :span="24" :md="12">
						<h5 class="font-semibold m-0">创作记录</h5>
					</a-col>
				</a-row>
			</template>
			<a-table :columns="writeTableColumns" :data-source="writeTableData" :pagination="false" :loading="writeTableLoading">
				<template slot="operate" slot-scope="row">
					<a-button type="link" @click="lookContent(row.textarea)">查看</a-button>
				</template>
			</a-table>
		</a-card>

		<a-modal v-model="contentModel" title="创作内容" :width="600" :cancel-button-props="{ style: { display: 'none' } }" okText="关闭" @ok="contentModel = false">
			<v-md-editor v-model="contentText" mode="preview"></v-md-editor>
		</a-modal>
	</div>
</template>

<script>
	import { timestampToTime } from "@/utils/tools"
	export default {
		props: {},
		data() {
			return {
				writeTableColumns: [
					{
						title: "ID",
						dataIndex: "id",
						class: "text-muted",
						width: 120
					},
					{
						title: "类型",
						dataIndex: "type",
						class: "text-muted",
						width: 300
					},
					{
						title: "创作条件",
						dataIndex: "title",
						class: "text-muted",
						ellipsis: true
					},
					{
						title: "生成时间",
						dataIndex: "time",
						class: "text-muted",
						width: 180
					},
					{
						title: "",
						scopedSlots: { customRender: "operate" },
						width: 120
					}
				],
				writeTableData: [],
				contentModel: false,
				contentText: "",
				writeTableLoading: true
			}
		},
		watch: {},
		created() {
			this.getWriteRecordData()
		},
		methods: {
			lookContent(data) {
				this.contentText = data
				this.contentModel = true
			},
			getWriteRecordData() {
				this.$http("write.record").then(res => {
					if (res.code === 1) {
						if (res.data && res.data.length) {
							const arr = []
							res.data.map(item => {
								arr.push({
									key: item.id,
									id: item.id,
									type: item.topic_title + " / " + item.prompt_title,
									title: item.message,
									time: timestampToTime(item.createtime),
									textarea: item.response
								})
							})
							this.writeTableData = this.writeTableData.concat(arr)
						}
					}
					this.writeTableLoading = false
				})
			}
		}
	}
</script>

<style lang="scss" scoped>
	::v-deep .ant-modal-body {
		max-height: 60vh;
		overflow-y: auto;

		&::-webkit-scrollbar {
			width: 12px;
		}

		&::-webkit-scrollbar-thumb {
			border-radius: 12px;
			border: 4px solid rgba(0, 0, 0, 0);
			box-shadow: 4px 0 0 #a5adb7 inset;
		}

		&::-webkit-scrollbar-thumb:hover {
			box-shadow: 4px 0 0 #4a4a4a inset;
		}

		// 滚动条两端按钮
		&::-webkit-scrollbar-button {
			height: 10px;
		}
	}
</style>
