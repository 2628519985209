<!-- 个人中心 -->

<template>
	<div>
		<div class="profile-nav-bg" style="background-image: url('images/bg-profile.jpg'); height: 200px"></div>
		<a-card :bordered="false" class="card-profile-head" :bodyStyle="{ padding: 0 }">
			<template #title>
				<a-row type="flex" align="middle">
					<a-col :span="24" :md="12" class="col-info">
						<a-avatar :size="74" shape="square" :src="userInfo.avatar" />
						<div class="avatar-info">
							<h4 class="font-semibold m-0">{{ userInfo.nickname }}</h4>
							<p>积分：{{ userInfo.usable || 0 }}</p>
							<p v-if="userInfo.is_vip === 1">VIP到期时间：{{ userInfo.vip_expiretime_text }}</p>
							<p v-if="userInfo.reseller">{{ userInfo.reseller.reseller_json.name }}到期时间：{{ userInfo.reseller.expiretime_text }}</p>
						</div>
					</a-col>
					<a-col :span="24" :md="12" style="display: flex; align-items: center; justify-content: flex-end">
						<a-radio-group v-model="tabsId" size="small">
							<a-radio-button :value="item.id" v-for="(item, index) in tabsList" :key="'tab_' + item.id">{{ item.title }}</a-radio-button>
						</a-radio-group>
					</a-col>
				</a-row>
			</template>
		</a-card>

		<div v-if="tabsId === 1">
			<a-row type="flex" :gutter="24">
				<a-col :span="24" :md="8" class="mb-24">
					<CardAccountInfo></CardAccountInfo>
				</a-col>
				<a-col :span="24" :md="8" class="mb-24">
					<CardBaseInfo></CardBaseInfo>
				</a-col>
				<a-col :span="24" :md="8" class="mb-24">
					<CardInviteInfo></CardInviteInfo>
				</a-col>
			</a-row>
		</div>
		<div v-if="tabsId === 2">
			<a-row :gutter="24" type="flex">
				<a-col :span="24" class="mb-24">
					<CardPlaceTable></CardPlaceTable>
				</a-col>
			</a-row>
		</div>
		<div v-if="tabsId === 3">
			<a-row :gutter="24" type="flex">
				<a-col :span="24" class="mb-24">
					<CardWriteTable></CardWriteTable>
				</a-col>
			</a-row>
		</div>
	</div>
</template>

<script>
	import CardAccountInfo from "../components/Cards/CardAccountInfo.vue"
	import CardBaseInfo from "../components/Cards/CardBaseInfo.vue"
	import CardInviteInfo from "../components/Cards/CardInviteInfo.vue"
	import CardPlaceTable from "../components/Cards/CardPlaceTable"
	import CardWriteTable from "../components/Cards/CardWriteTable"

	import { mapState, mapGetters, mapMutations, mapActions } from "vuex"
	export default {
		components: {
			CardAccountInfo,
			CardBaseInfo,
			CardInviteInfo,
			CardPlaceTable,
			CardWriteTable
		},
		data() {
			return {
				tabsId: 1,
				tabsList: [
					{ id: 1, title: "信息总览" },
					{ id: 2, title: "分销中心" },
					{ id: 3, title: "创作稿件" }
				]
			}
		},
		computed: {
			...mapGetters("user", ["token", "userInfo"])
		},
		watch: {},
		created() {
			this.getUserInfo()
			this.getStatisticInfo()
		},
		methods: {
			...mapActions("user", ["getUserInfo", "getStatisticInfo"])
		}
	}
</script>

<style lang="scss"></style>
